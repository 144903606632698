
import { Component, Prop } from 'vue-property-decorator';
import VFormBuilder from '../shared/form/VFormBuilder.vue';
import { InputType } from '@/enums/InputType';
import StackedForm from '../../mixins/StackedForm';
import { mixins } from 'vue-class-component';
import { Role } from '@/interfaces/models/Role';
import role from '../../stores/role';
import { Venue } from '@/interfaces/models/Venue';
import { VenueLabel } from '@/interfaces/models/VenueLabel';
import { FormItem } from '@/interfaces/components/FormItem';
import CustomerGroup from '@/mixins/CustomerGroup';
import { Permission } from '@/enums/Permission';
import { TranslateResult } from 'vue-i18n';
import { venueFilter } from '@/util/helper';

interface VenueLabelSelectItem {
  text: string | TranslateResult;
  value: string;
  venues: string[];
}

@Component({
  components: { VFormBuilder },
})
export default class UserForm extends mixins(StackedForm, CustomerGroup) {
  @Prop({ type: Array, default: () => [] }) public roles!: Role[];
  @Prop({ type: Array, required: true }) public venues!: Venue[];
  @Prop({ type: Array, default: () => [] }) public labels!: VenueLabel[];
  @Prop({ type: Boolean, default: false }) public editing!: boolean;

  public $refs!: {
    form: InstanceType<typeof VFormBuilder> & { form: any };
  };

  get items() {
    const items: FormItem[] = [
      { name: 'name', type: InputType.Text, label: 'user.form.name', rules: 'required' },
      { name: 'email', type: InputType.Text, label: 'user.form.email', rules: this.emailRules },
      {
        name: 'password',
        type: InputType.PasswordConfirmation,
        label: 'user.form.password',
        hint: 'user.form.passwordHint',
        rules: 'required|password|min:8',
      },
      {
        name: 'role',
        type: InputType.Autocomplete,
        label: 'user.form.role',
        rules: 'required',
        items: this.roleItems,
        multiple: false,
      },
      {
        name: 'venues',
        type: InputType.Autocomplete,
        label: 'user.form.venues',
        itemValue: '_id',
        itemText: 'name',
        items: this.venues,
        filter: venueFilter,
        multiple: true,
      },
      {
        name: 'venueLabels',
        type: InputType.Select,
        label: 'user.form.venueLabels',
        items: this.venueLabelsWithEmpty,
        change: this.onLabelChanged,
        multiple: true,
        useChips: true,
        visible: this.$can(Permission.LABEL_VIEW),
      },
    ];

    if (this.$isAdmin()) {
      items.push({
        name: 'customerGroup',
        type: InputType.Autocomplete,
        label: 'user.form.customerGroup',
        items: this.customerGroups,
        multiple: false,
      });
      items.push({
        name: 'linkedCustomerGroup',
        type: InputType.Autocomplete,
        label: 'user.form.linkedCustomerGroup',
        items: this.customerGroups,
        multiple: false,
      });
    }

    if (this.editing) {
      return items.filter((i: FormItem) => i.name !== 'password');
    }

    return items;
  }

  get emailRules() {
    if (this.editing) {
      return `required|email|unique_email:${this.$route.params.id}`;
    }
    return `required|email|unique_email`;
  }

  get roleItems() {
    return this.roles.map((r: Role) => {
      return {
        text: r.name,
        value: r.slug,
      };
    });
  }

  get venueLabelsWithEmpty() {
    const labels: VenueLabelSelectItem[] = this.labels.map((label: VenueLabel) => {
      return { text: label.name, value: label.slug, venues: label.venues };
    });

    return [{ text: this.$t('common.noSelection'), value: '', venues: [] }, ...labels];
  }

  public onLabelChanged($event: string[], form: any) {
    // unselect other labels if noSelection is chosen
    if ($event.includes('') && form.venueLabels) {
      form.venueLabels = [''];
    }

    // change disabled state of venues
    const selectedLabels: VenueLabelSelectItem[] = this.venueLabelsWithEmpty.filter(
      (item: VenueLabelSelectItem) => form.venueLabels && form.venueLabels.includes(item.value),
    );

    const formVenues: FormItem | undefined =
      this.$refs.form && this.$refs.form.items.find((item: FormItem) => item.name === 'venues');

    const formVenuesItems: any[] = (formVenues && Array.isArray(formVenues.items) && formVenues.items) || [];

    const selectedVenues: Set<string> = new Set<string>();

    selectedLabels.forEach((item: VenueLabelSelectItem) =>
      item.venues.forEach((venue: string) => selectedVenues.add(venue)),
    );

    formVenuesItems.forEach((item: any) => (item.disabled = selectedVenues.has(item.value)));

    // select only venues of selected venue labels
    form.venues = formVenuesItems.filter((item: any) => item.disabled).map((item: any) => item.value);
  }
}
