
import { Component } from 'vue-property-decorator';
import { InputType } from '../../enums/InputType';
import StackedForm from '@/mixins/StackedForm';
import VFormBuilder from '@/components/shared/form/VFormBuilder.vue';
import { mixins } from 'vue-class-component';

@Component({
  components: { VFormBuilder },
})
export default class ResetPasswordForm extends mixins(StackedForm) {
  get items() {
    return [
      {
        name: 'password',
        label: 'user.form.password',
        type: InputType.PasswordConfirmation,
        rules: 'required|password|min:8',
        hint: 'auth.form.passwordHint',
      },
    ];
  }
}
