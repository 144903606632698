
import { Component } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import Editable from '@/mixins/Editable';
import VWrapper from '@/components/shared/VWrapper.vue';
import { namespace } from 'vuex-class';
import Notification from '@/mixins/Notification';
import RoleForm from '../../components/role/RoleForm.vue';
import EditRoleLoader from '@/components/role/EditRoleLoader.vue';
import UserForm from '@/components/user/UserForm.vue';
import { Venue } from '@/interfaces/models/Venue';
import { VenueLabel } from '@/interfaces/models/VenueLabel';
import { User } from '@/interfaces/models/User';
import StackedForm from '@/mixins/StackedForm';
import { CustomerGroup } from '@/enums/CustomerGroup';
import ResetPasswordForm from '@/components/user/ResetPasswordForm.vue';
import UserApiService from '@/api/http/UserApiService';
import { Permission } from '@/enums/Permission';

const user = namespace('user');
const app = namespace('app');
const auth = namespace('auth');
const venueLabel = namespace('venueLabel');

@Component({
  components: { ResetPasswordForm, UserForm, EditRoleLoader, RoleForm, VWrapper },
})
export default class EditSimpleUser extends mixins(Editable, Notification, StackedForm) {
  @auth.State('user') public authUser!: Partial<User>;

  @user.Action('storeRestricted') public store!: any;
  @user.Action('show') public show!: any;
  @user.Action('updateRestricted') public update!: any;
  @user.State('active') public active!: User;

  @app.Action('fetchVenues') public getVenues!: any;
  @app.State('venues') public venues!: Venue[];

  @venueLabel.Action('fetch') public getVenueLabels!: any;
  @venueLabel.State('items') public venueLabels!: VenueLabel[];

  public $refs!: {
    form: InstanceType<typeof UserForm> & { getData: () => any; validate: () => Promise<boolean | boolean[]> };
    passwordForm: InstanceType<typeof UserForm> & { getData: () => any; validate: () => Promise<boolean | boolean[]> };
  };

  // TODO: hardcoded, refactor in the future
  get roles() {
    const linkedCustomerGroup: CustomerGroup = this.$getLinkedCustomerGroup();
    if (linkedCustomerGroup === CustomerGroup.Subway) {
      return [
        {
          slug: 'fn',
          name: 'Subway® Franchisenehmer',
        },
        {
          slug: 'subway-employee',
          name: 'Subway® Sandwich Artist',
        },
        {
          slug: 'subway-manager',
          name: 'Subway® Store Manager',
        },
      ];
    } else if (linkedCustomerGroup === CustomerGroup.SubwayFr) {
      return [
        {
          slug: 'subway_fr-fn',
          name: 'Subway® FR Franchisee',
        },
        {
          slug: 'subway_fr-employee',
          name: 'Subway® FR Sandwich Artist',
        },
        {
          slug: 'subway_fr-manager',
          name: 'Subway® FR Store Manager',
        },
      ];
    } else if (linkedCustomerGroup === CustomerGroup.SubwayLu) {
      return [
        {
          slug: 'subway_lu-fn',
          name: 'Subway® LU Franchisee',
        },
        {
          slug: 'subway_lu-employee',
          name: 'Subway® LU Sandwich Artist',
        },
        {
          slug: 'subway_lu-manager',
          name: 'Subway® LU Store Manager',
        },
      ];
    } else if (linkedCustomerGroup === CustomerGroup.Kochloeffel) {
      return [
        {
          slug: 'kochloeffel-fn',
          name: 'Kochlöffel Franchisenehmer',
        },
        {
          slug: 'kochloeffel-employee',
          name: 'Kochlöffel Mitarbeiter',
        },
        {
          slug: 'kochloeffel-manager',
          name: 'Kochlöffel Filialleiter',
        },
        {
          slug: 'kochloeffel-counter',
          name: 'Kochlöffel TresenApp',
        },
        {
          slug: 'kochloeffel-accounting',
          name: 'Kochlöffel Accounting',
        },
      ];
    }
    if (linkedCustomerGroup !== CustomerGroup.Default) {
      return [
        {
          slug: `${linkedCustomerGroup}-fn`,
          name: 'Franchisenehmer',
        },
        {
          slug: `${linkedCustomerGroup}-employee`,
          name: 'Mitarbeiter',
        },
        {
          slug: `${linkedCustomerGroup}-manager`,
          name: 'Filialleiter',
        },
        {
          slug: `${linkedCustomerGroup}-counter`,
          name: 'TresenApp',
        },
      ];
    }

    return [];
  }

  public changePassword() {
    this.$refs.passwordForm.validate().then(async (res: boolean | boolean[]) => {
      if (this.isValid(res)) {
        const api: UserApiService = new UserApiService();
        await api.changePassword({ id: this.id, ...this.$refs.passwordForm.getData() });
        this.$router.push({ name: 'user.index' });
      } else {
        this.notifyError('notification.form');
      }
    });
  }

  get title() {
    return this.editing ? 'user.edit' : 'user.create';
  }

  get user() {
    if (this.editing && this.active) {
      return this.active;
    }

    return null;
  }

  public async mounted() {
    this.$startLoading('venueLabels');
    if (this.$can(Permission.LABEL_VIEW)) {
      await this.getVenueLabels();
    }

    if (this.editing) {
      this.$startLoading('user');
      await this.show({ id: this.id });
    }

    if (this.editing && !this.active) {
      this.$router.push({ name: 'user.index' });
    }
    this.$stopLoading('user');
    this.$stopLoading('venueLabels');
  }

  public save() {
    this.$refs.form.validate().then(async (res: boolean | boolean[]) => {
      if (this.isValid(res)) {
        if (this.editing) {
          await this.update({
            ...this.$refs.form.getData(),
            id: this.active!._id,
            linkedCustomerGroup: this.authUser.linkedCustomerGroup,
          });
        } else {
          await this.store({ ...this.$refs.form.getData(), linkedCustomerGroup: this.authUser.linkedCustomerGroup });
        }
        this.$router.push({ name: 'user.index' });
      } else {
        this.notifyError('notification.form');
      }
    });
  }
}
